import React, {useEffect, useRef} from "react";

import './video.less';

export default function NewsVideo(props) {
    const videoWtuRef = useRef(undefined);

    useEffect(() => {
        videoWtuRef.current.defaultMuted = true;
    })

    const img = require(`../../assets/news/video/${props.img}`)
    const video = require(`../../assets/news/video/${props.video}`)

    return <video loop autoPlay muted playsInline
                  className="news_video"
                  ref={videoWtuRef}
                  poster={img.default}>
        <source src={video.default} type="video/mp4"/>
    </video>
}
