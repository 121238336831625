import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/code/src/components/news/layout.jsx";
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { useI18next } from 'gatsby-plugin-react-i18next';
import SEO from '../../components/seo';
import NewsHeader from '../../components/news/header';
import NewsImgWithDescription from '../../components/news/imgWithDescription';
import NewsImg from '../../components/news/img';
import NewsVideo from '../../components/news/video';
export const query = graphql`
    query($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <SEO title={"CityAir has verified Air Quality data from AirQuality.report and agreed on project integration into the  AirVoice Platform"} description={"CityAir (CityAir and AirVoice Platforms) and EQOlabs (AirQuality.report service) have signed an agreement which will make representative Air Quality Information available on AirVoice open map."} lang={useTranslation().language} link={useI18next().path} mdxType="SEO" />
    <NewsHeader img="1.jpg" tag="news" title="CityAir has verified Air Quality data from AirQuality.report and agreed on project integration into the  AirVoice Platform" description="CityAir (CityAir and AirVoice Platforms) and EQOlabs
     (AirQuality.report service) have signed an agreement which will make
     representative Air Quality Information available on AirVoice open map." mdxType="NewsHeader" />
    <NewsHeader tag="news" title="CityAir has verified Air Quality data from AirQuality.report and agreed on project integration into the  AirVoice Platform" description="CityAir (CityAir and AirVoice Platforms) and EQOlabs
     (AirQuality.report service) have signed an agreement which will make
     representative Air Quality Information available on AirVoice open map." mdxType="NewsHeader" />
    <blank-space />
    <hr></hr>
    <h3><strong parentName="h3">{`Dmitry Chubarov`}</strong>{`, researcher, head of CityAir modelling group:`}</h3>
    <blockquote>
      <p parentName="blockquote">{`It’s very difficult to balance between model accuracy and computational complexity.
EQOlabs team has developed the technology which allows to receive detailed air
quality maps in hundreds of cities all over the world without using supercomputer
techniques.`}</p>
    </blockquote>
    <NewsImgWithDescription img="test.jpg" description="The calculation results from EQOlabs and CityAir Monitors measurements
    in Moscow and Saint Petersburg. As a result the data from EQOlabs
    have shown great compliance rate to CityAir measurements. The diagram shows
    comparative calculations of one of the monitors." mdxType="NewsImgWithDescription" />
    <p>{`пустой отступ`}</p>
    <blank-space />
    <h1>{`CityAir has verified Air Quality data from AirQuality.report and agreed on project integration into the AirVoice Platform`}</h1>
    <h2>{`CityAir has verified Air Quality data from AirQuality.report and agreed on project integration into the  AirVoice Platform`}</h2>
    <h3>{`Before the agreement was signed the parties conducted the research to compare the calculation results from EQOlabs and CityAir Monitors measurements in Moscow and Saint Petersburg.  As a result the data from EQOlabs have shown great compliance rate to CityAir measurements.`}</h3>
    <h3>либо можно писать не через решетку а через h3
не боясь переносить строки </h3>
    <p>{`It’s very difficult to balance between model accuracy and computational complexity. EQOlabs team has developed the technology
which allows to receive detailed air quality maps in hundreds of cities all over the world without using supercomputer techniques.`}</p>
    <small>The calculation results from EQOlabs and CityAir Monitors measurements in Moscow and Saint Petersburg.
As a result the data from EQOlabs have shown great compliance rate to CityAir measurements. The diagram shows
comparative calculations of one of the monitors.</small>
    <NewsImg img="test.jpg" mdxType="NewsImg" />
    <p>{`Это встроенная `}<sup>{`2`}</sup>{` `}<a parentName="p" {...{
        "href": "http://example.com/link"
      }}>{`ссылка`}</a>{` CO`}<sub>{`2`}</sub>{`.`}</p>
    <NewsVideo video="vulkan.mp4" img="vulkan.jpg" mdxType="NewsVideo" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      